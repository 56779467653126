export const getZones = rsr => {
  let zones = [];

  var _122 = {
    path: 'M1069.721,609.418h18.48l12.745,73.623h-31.225Z',
    attr: {
      id: '122',
      'data-name': '122',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.117 0.843',
    data: {
      zoneNo: 122,
      zone: 'Lower Club',
      zoneId: 3
    }
  };

  var _121 = {
    path: 'M994.314,609.418h71.371v73.623H994.314Z',
    attr: {
      id: '121',
      'data-name': '121',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.106 0.843',
    data: {
      zoneNo: 121,
      zone: 'Lower Club',
      zoneId: 3
    }
  };

  var _120 = {
    path: 'M915.211,444.843h75.414V384.706H915.211Z',
    attr: {
      id: '120',
      'data-name': '120',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.089 239.04',
    data: {
      zoneNo: 120,
      zone: 'Lower Club',
      zoneId: 3
    }
  };

  var _119 = {
    path: 'M994.314,609.418h68.561v73.623H994.314Z',
    attr: {
      id: '119',
      'data-name': '119',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-151.262 0.843',
    data: {
      zoneNo: 119,
      zone: 'Lower Club',
      zoneId: 3
    }
  };

  var _118 = {
    path:
      'M839.218,460.893s-34.516-.194-49.5-8.025c8.508-22.141,25.815-68.162,25.815-68.162s7.928,3.191,23.688,2.61Z',
    attr: {
      id: '118',
      'data-name': '118',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.059 222.992',
    data: {
      zoneNo: 118,
      zone: 'Lower Club',
      zoneId: 3
    }
  };

  var _117 = {
    path:
      'M786.19,474.829s-35.549-11.346-56.137-42.065c39.378-25.653,58.659-38.888,58.659-38.888s7.68,9.8,23.039,13.4C805.543,423.941,786.19,474.829,786.19,474.829Z',
    attr: {
      id: '117',
      'data-name': '117',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.047 199.887',
    data: {
      zoneNo: 117,
      zone: 'Lower Club',
      zoneId: 3
    }
  };

  var _116 = {
    path:
      'M786.187,460.777s-7.164-8.3-7.667-22.121H707.631s-.126,33.056,20.11,61.211C754.136,482.4,786.187,460.777,786.187,460.777Z',
    attr: {
      id: '116',
      'data-name': '116',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.042 130.079',
    data: {
      zoneNo: 116,
      zone: 'Lower Club',
      zoneId: 3
    }
  };

  var _115 = {
    path: 'M737.226,503v30h72V503Z',
    attr: {
      id: '115',
      'data-name': '115',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-30.268 31.906',
    data: {
      zoneNo: 115,
      zone: 'Lower Club',
      zoneId: 3
    }
  };

  var _114 = {
    path: 'M737.226,503v30h72V503Z',
    attr: {
      id: '114',
      'data-name': '114',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-30.268 -0.087',
    data: {
      zoneNo: 114,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _113 = {
    path:
      'M786.187,477.745s-7.164,8.3-7.667,22.121H707.631s-.126-33.056,20.11-61.211C754.136,456.127,786.187,477.745,786.187,477.745Z',
    attr: {
      id: '113',
      'data-name': '113',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.042 -0.076',
    data: {
      zoneNo: 113,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _112 = {
    path:
      'M786.19,393.876s-35.549,11.346-56.137,42.065c39.378,25.653,58.659,38.888,58.659,38.888s7.68-9.8,23.039-13.4C805.543,444.764,786.19,393.876,786.19,393.876Z',
    attr: {
      id: '112',
      'data-name': '112',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.047 -0.068',
    data: {
      zoneNo: 112,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _111 = {
    path:
      'M839.218,384.706s-34.516.194-49.5,8.025c8.508,22.141,25.815,68.162,25.815,68.162s7.928-3.191,23.688-2.61Z',
    attr: {
      id: '111',
      'data-name': '111',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.059 -0.065',
    data: {
      zoneNo: 111,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _110 = {
    path:
      'M910.708,384.706v60.155H898.834V431.054h-3.866v27.338h-52.19V384.706Z',
    attr: {
      id: '110',
      'data-name': '110',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.072 -0.065',
    data: {
      zoneNo: 110,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _109 = {
    path: 'M915.211,384.706h75.414v60.137H915.211Z',
    attr: {
      id: '109',
      'data-name': '109',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.089 -0.064',
    data: {
      zoneNo: 109,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _108 = {
    path: 'M994.1,384.706v60.106h13.158v-13.1h3.758v26.614h54.979v-73.62Z',
    attr: {
      id: '108',
      'data-name': '108',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.106 -0.065',
    data: {
      zoneNo: 108,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _107 = {
    path:
      'M1069.589,384.706s41.012-2.359,69.933,9.9c-19.444,45.424-28.431,67.319-28.431,67.319a51.073,51.073,0,0,0-17.32-3.758c-9.154-.176-24.183,0-24.183,0Z',
    attr: {
      id: '107',
      'data-name': '107',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.122 -0.065',
    data: {
      zoneNo: 107,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _106 = {
    path:
      'M1143.548,395.735l13.09,6.768,14.8-16.957,20.3,14.5H1237.7l9.148,15.246-111.707,63.488a44.038,44.038,0,0,0-21.121-16.038C1124.435,439.392,1143.548,395.735,1143.548,395.735Z',
    attr: {
      id: '106',
      'data-name': '106',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.143 -0.067',
    data: {
      zoneNo: 106,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _105 = {
    path:
      'M1248.6,418.487s20.157,29.071,22.918,79.625c-63.724,4.886-128.936,9.559-128.936,9.559s1.487-16.144-4.673-25.915C1219.049,435.663,1248.6,418.487,1248.6,418.487Z',
    attr: {
      id: '105',
      'data-name': '105',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 -0.074',
    data: {
      zoneNo: 105,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _104 = {
    path: 'M1143.177,534.365h36.666V508.707l92.222-6.768v64.7l-128.887-10.189Z',
    attr: {
      id: '104',
      'data-name': '104',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 -0.09',
    data: {
      zoneNo: 104,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _103 = {
    path:
      'M1137.4,586.1s63.4,36.274,111.6,63.888c24.509-44.607,22.876-79.574,22.876-79.574l-128.7-10.294S1142.628,581.853,1137.4,586.1Z',
    attr: {
      id: '103',
      'data-name': '103',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 -0.107',
    data: {
      zoneNo: 103,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _102 = {
    path:
      'M1135.368,589.326s-6.694,9.817-21.642,15.618c5.5,12.123,29.491,67.178,29.491,67.178l13.255-6.351,14.911,16.844,21.263-14.911h45.563l9.006-14.5Z',
    attr: {
      id: '102',
      'data-name': '102',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.143 -0.114',
    data: {
      zoneNo: 102,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _101 = {
    path:
      'M1110.093,605.963s-3.776,2.317-18.135,3.417c1.659,10.079,12.6,74.064,12.6,74.064s18.905-1.29,35.473-9.033C1123.25,636.345,1110.093,605.963,1110.093,605.963Z',
    attr: {
      id: '101',
      'data-name': '101',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.124 0.44',
    data: {
      zoneNo: 101,
      zone: 'Lower Bowl',
      zoneId: 3
    }
  };

  var _228 = {
    path:
      'M1103.384,367.844s48.342-2.975,81.066-15.321c-5.8-18-38.822-118.847-38.822-118.847l14.577-4.76-7.288-22.758s-24.989,11.6-64.852,11.751v25.138h15.618Z',
    attr: {
      id: '228',
      'data-name': '228',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.131 494.05',
    data: {
      zoneNo: 228,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _227 = {
    path:
      'M1015.654,356.368h84.1V233.991h-15.814V205.428h-83.483v25.114h15.618Z',
    attr: {
      id: '227',
      'data-name': '227',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.111 506.258',
    data: {
      zoneNo: 227,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _226 = {
    path:
      'M926.313,356.368h85.958V234.114H996.653V205.428H910.718v25.131h15.691Z',
    attr: {
      id: '226',
      'data-name': '226',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.091 506.258',
    data: {
      zoneNo: 226,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _225 = {
    path: 'M836.63,356.368h85.929V234.1H907.044V205.428H821.516v25.258H836.63Z',
    attr: {
      id: '225',
      'data-name': '225',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.07 506.258',
    data: {
      zoneNo: 225,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _224 = {
    path:
      'M833.057,372.456v-122.4H817.35v-29.6s-31.683-1.339-56.523-15.023c-7.288,16.064-16.362,37.335-16.362,37.335L769.6,254.216,737.326,355.957S769.036,369.915,833.057,372.456Z',
    attr: {
      id: '224',
      'data-name': '224',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.05 490.17',
    data: {
      zoneNo: 224,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _223 = {
    path:
      'M733.012,344.641l31.683-98.915-49.532-22.609-59.2,94.3a239.95,239.95,0,0,0,41.351,23.5c3.719-7.735,4.611-9.966,4.611-9.966Z',
    attr: {
      id: '223',
      'data-name': '223',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.032 500.296',
    data: {
      zoneNo: 223,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _222 = {
    path:
      'M652.988,419.013s-29.9-20.229-46.111-39.715c7.883-6.545,10.561-8.925,10.561-8.925l-11.6-14.131,56.374-47.3-9.52-11.6,52.953-44.475s16.511,26.477,51.763,44.623c-9.668,21.27-17.254,37.186-17.254,37.186l-26.179-12.346Z',
    attr: {
      id: '222',
      'data-name': '222',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t0 396.216',
    data: {
      zoneNo: 222,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _221 = {
    path:
      'M603.456,461.256l-12.792-15.469-10.412,8.33S554.519,415,545.594,390.752C585.458,374.687,646.889,349.4,646.889,349.4l-5.5-14.131,43.136-17.254s6.4,21.27,18.742,39.12c-34.955,29.005-56.374,47.9-56.374,47.9l9.52,11.3Z',
    attr: {
      id: '221',
      'data-name': '221',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t0 288.837',
    data: {
      zoneNo: 221,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _220 = {
    path:
      'M683.183,423.773s-4.165-12.048-4.611-31.831c-51.614,2.677-85.974,4.76-85.974,4.76v15.321l-69.612,9.222s8.478,48.044,21.27,75.562c32.129-13.238,97.874-40.31,97.874-40.31l-5.652-13.833Z',
    attr: {
      id: '220',
      'data-name': '220',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t0 179.375',
    data: {
      zoneNo: 220,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _219 = {
    path:
      'M678.469,501V567.36l-89.742,4.776v15.083l-66.112,10.558s-6.033-71.391,0-126.946c37.707,5.782,66.112,10.558,66.112,10.558v14.58Z',
    attr: {
      id: '219',
      'data-name': '219',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t0 -0.09',
    data: {
      zoneNo: 219,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _218 = {
    path:
      'M683.183,464.975s-4.165,12.048-4.611,31.831c-51.614-2.677-85.974-4.76-85.974-4.76V476.726L522.985,467.5s8.478-48.044,21.27-75.562c32.129,13.238,97.874,40.31,97.874,40.31l-5.652,13.833Z',
    attr: {
      id: '218',
      'data-name': '218',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t0 -0.069',
    data: {
      zoneNo: 218,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _217 = {
    path:
      'M603.456,318.016l-12.792,15.469-10.412-8.33s-25.733,39.12-34.657,63.365c39.863,16.064,101.295,41.351,101.295,41.351L641.386,444l43.136,17.254s6.4-21.27,18.742-39.12c-34.955-29.005-56.374-47.9-56.374-47.9l9.52-11.3Z',
    attr: {
      id: '217',
      'data-name': '217',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t0 -0.055',
    data: {
      zoneNo: 217,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _216 = {
    path:
      'M652.988,252.865s-29.9,20.229-46.111,39.715c7.883,6.545,10.561,8.925,10.561,8.925l-11.6,14.131,56.374,47.3-9.52,11.6,52.953,44.475s16.511-26.477,51.763-44.623c-9.668-21.27-17.254-37.186-17.254-37.186l-26.179,12.346Z',
    attr: {
      id: '216',
      'data-name': '216',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t0 -0.04',
    data: {
      zoneNo: 216,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _215 = {
    path:
      'M733.012,223.117l31.683,98.915-49.532,22.609-59.2-94.3a239.95,239.95,0,0,1,41.351-23.5c3.719,7.735,4.611,9.966,4.611,9.966Z',
    attr: {
      id: '215',
      'data-name': '215',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.032',
    data: {
      zoneNo: 215,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _214 = {
    path:
      'M833.057,205.428v122.4H817.35v29.6s-31.683,1.339-56.523,15.023c-7.288-16.064-16.362-37.335-16.362-37.335L769.6,323.668,737.326,221.927S769.036,207.969,833.057,205.428Z',
    attr: {
      id: '214',
      'data-name': '214',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.05',
    data: {
      zoneNo: 214,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _213 = {
    path:
      'M836.63,205.428h85.929V327.693H907.044v28.675H821.516V331.11H836.63Z',
    attr: {
      id: '213',
      'data-name': '213',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.07',
    data: {
      zoneNo: 213,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _212 = {
    path:
      'M926.313,205.428h85.958V327.682H996.653v28.686H910.718V331.237h15.691Z',
    attr: {
      id: '212',
      'data-name': '212',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.091',
    data: {
      zoneNo: 212,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _211 = {
    path: 'M1015.654,205.428h84.1V327.8h-15.814v28.563h-83.483V331.253h15.618Z',
    attr: {
      id: '211',
      'data-name': '211',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.111',
    data: {
      zoneNo: 211,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _210 = {
    path:
      'M1103.384,206.159s48.342,2.975,81.066,15.321c-5.8,18-38.822,118.847-38.822,118.847l14.577,4.76-7.288,22.758s-24.989-11.6-64.852-11.751V330.956h15.618Z',
    attr: {
      id: '210',
      'data-name': '210',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.131',
    data: {
      zoneNo: 210,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _209 = {
    path:
      'M1188.125,222.473s18.145,6.364,29.316,11.645c1.828-3.724,4.265-9,4.265-9s18.551,7.583,42.992,24.712c-6.838,11.1-66.98,108.485-66.98,108.485l12.376,8.121-16.05,25.138a126.968,126.968,0,0,0-37.513-22.044c7.348-22.817,8.895-27.458,8.895-27.458l-14.889-4.834Z',
    attr: {
      id: '209',
      'data-name': '209',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.148',
    data: {
      zoneNo: 209,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _208 = {
    path:
      'M1268.1,251.764c-.58,1.16-65.552,105.385-65.552,105.385l12.762,7.928-16.823,27.265h60.717l-22.044-23.591,17.21-19.143,10.828,10.055,53.756-62.071S1297.495,271.874,1268.1,251.764Z',
    attr: {
      id: '208',
      'data-name': '208',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 -0.039',
    data: {
      zoneNo: 208,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _207 = {
    path:
      'M1321.472,300.3s25.911,28.425,35.966,50.662c-8.315,4.447-12.569,6.961-12.569,6.961l10.249,17.4-53.95,33.453-7.928-13.342-15.276,10.442-35.58-37.513,11.989-13.342,11.215,10.829Z',
    attr: {
      id: '207',
      'data-name': '207',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 -0.048',
    data: {
      zoneNo: 207,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _206 = {
    path:
      'M1278.055,410.076l14.073-8.581,8.124,13.5,56.866-36.614,9.154,16.247,10.526-6.179s13.73,30.779,19.108,62.015c-32.037,6.636-75.631,16.019-75.631,16.019l-3.432-15.447-18.421,4.233S1292.015,428.383,1278.055,410.076Z',
    attr: {
      id: '206',
      'data-name': '206',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 -0.065',
    data: {
      zoneNo: 206,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _205 = {
    path:
      'M1299.429,459.054s12.852,83.419,0,149.758c10.024,1.961,15.242,3.1,15.242,3.1l3.089-14.874,79.292,17.163s10.477-71.072,0-160.613c-44.136,9.477-79.292,16.993-79.292,16.993l-3.089-15.032Z',
    attr: {
      id: '205',
      'data-name': '205',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 -0.09',
    data: {
      zoneNo: 205,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _204 = {
    path:
      'M1278.055,434.791l14.073,8.581,8.124-13.5,56.866,36.614,9.154-16.247,10.526,6.179s13.73-30.779,19.108-62.015c-32.037-6.636-75.631-16.019-75.631-16.019l-3.432,15.447-18.421-4.233S1292.015,416.484,1278.055,434.791Z',
    attr: {
      id: '204',
      'data-name': '204',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 223.252',
    data: {
      zoneNo: 204,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _203 = {
    path:
      'M1321.472,408.779s25.911-28.425,35.966-50.662c-8.315-4.447-12.569-6.961-12.569-6.961l10.249-17.4-53.95-33.453-7.928,13.342L1277.965,303.2l-35.58,37.513,11.989,13.342,11.215-10.829Z',
    attr: {
      id: '203',
      'data-name': '203',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 359.024',
    data: {
      zoneNo: 203,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _202 = {
    path:
      'M1268.1,392.342c-.58-1.16-65.552-105.385-65.552-105.385l12.762-7.928-16.823-27.265h60.717l-22.044,23.591,17.21,19.143,10.828-10.055,53.756,62.071S1297.495,372.232,1268.1,392.342Z',
    attr: {
      id: '202',
      'data-name': '202',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.181 423.986',
    data: {
      zoneNo: 202,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _201 = {
    path:
      'M1188.125,391.569s18.145-6.364,29.316-11.645c1.828,3.724,4.265,9,4.265,9s18.551-7.583,42.992-24.712c-6.838-11.1-66.98-108.485-66.98-108.485l12.376-8.121-16.05-25.138a126.968,126.968,0,0,1-37.513,22.044c7.348,22.817,8.895,27.458,8.895,27.458l-14.889,4.834Z',
    attr: {
      id: '201',
      'data-name': '201',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 't-519.934 -93.181 t-0.148 454.012',
    data: {
      zoneNo: 201,
      zone: 'Second Level',
      zoneId: 2
    }
  };

  var _rest = {
    path: 'm325.81 84.702 226.27-0.18481v19.96l-226.09 0.18481z',
    attr: {
      id: '1',
      'data-name': '1',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 'matrix(1,0,0,1,0,0)',
    data: {
      zoneNo: "",
      zone: 'REST',
      zoneId: 1
    }
  };

  var _platinum_club = {
    path: 'm325.45 774.72 226.57-0.1848v19.959l-226.39 0.1848z',
    attr: {
      id: '1',
      'data-name': '1',
      fill: '#2196f3',
      opacity: '0',
      'stroke-width': '0',
      'stroke-opacity': '1'
    },
    transform: 'matrix(1,0,0,1,0,0)',
    data: {
      zoneNo: "",
      zone: 'Platinum Club',
      zoneId: 1
    }
  };

  var _PL1 = {
    path:
      'M 673.00,800.00 C 673.00,800.00 704.00,790.58 704.00,790.58 704.00,790.58 754.00,776.00 754.00,776.00 754.00,776.00 766.00,803.00 766.00,803.00 766.00,803.00 740.00,810.72 740.00,810.72 740.00,810.72 681.00,828.00 681.00,828.00 681.00,828.00 673.00,800.00 673.00,800.00 Z',
    attr: {
      id: '_PL1',
      'data-name': 'PL1',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "1", zone: 'Press Level', zoneId: "3" },
  };

  var _PL2 = {
    path:
      'M 622.00,801.00 C 622.00,801.00 671.00,801.00 671.00,801.00 671.00,801.00 679.00,829.00 679.00,829.00 679.00,829.00 621.00,829.00 621.00,829.00 621.00,829.00 622.00,801.00 622.00,801.00 ',
    attr: {
      id: '_PL2',
      'data-name': 'PL2',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "2", zone: 'Press Level', zoneId: "3" },
  };

  var _PL3 = {
    path:
      'M 547.00,829.00 C 547.00,829.00 547.00,808.00 547.00,808.00 547.04,806.05 546.90,803.37 548.60,802.02 550.19,800.77 554.01,801.00 556.00,801.00 556.00,801.00 611.00,801.00 611.00,801.00 619.64,801.02 619.98,801.36 620.00,810.00 620.00,810.00 620.00,829.00 620.00,829.00 620.00,829.00 547.00,829.00 547.00,829.00 Z',
    attr: {
      id: '_PL3',
      'data-name': 'PL3',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "3", zone: 'Press Level', zoneId: "3" },
  };

  var _PL4 = {
    path:
      'M 481.00,802.00 C 481.00,802.00 545.00,802.00 545.00,802.00 545.00,802.00 545.00,830.00 545.00,830.00 545.00,830.00 481.00,830.00 481.00,830.00 481.00,830.00 481.00,802.00 481.00,802.00 Z',
    attr: {
      id: '_PL4',
      'data-name': 'secton',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "4", zone: 'Press Level', zoneId: "3" },
  };

  var _PL5 = {
    path:
      'M 405.00,802.00 C 405.00,802.00 478.00,802.00 478.00,802.00 478.00,802.00 478.00,830.00 478.00,830.00 478.00,830.00 405.00,830.00 405.00,830.00 405.00,830.00 405.00,802.00 405.00,802.00 Z',
    attr: {
      id: '_PL5',
      'data-name': 'PL5',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "5", zone: 'Press Level', zoneId: "3" },
  };

  var _PL6 = {
    path:
      'M 338.00,802.00 C 338.00,802.00 402.00,802.00 402.00,802.00 402.00,802.00 402.00,830.00 402.00,830.00 402.00,830.00 347.00,830.00 347.00,830.00 338.36,829.98 338.02,829.64 338.00,821.00 338.00,821.00 338.00,802.00 338.00,802.00 Z',
    attr: {
      id: '_PL6',
      'data-name': 'PL6',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "6", zone: 'Press Level', zoneId: "3" },
  };

  var _PL7 = {
    path:
      'M 264.00,801.00 C 264.00,801.00 318.00,801.00 318.00,801.00 321.39,801.00 333.00,800.10 334.98,802.60 336.23,804.19 336.00,808.01 336.00,810.00 336.00,810.00 336.00,829.00 336.00,829.00 336.00,829.00 264.00,829.00 264.00,829.00 264.00,829.00 264.00,801.00 264.00,801.00 Z',
    attr: {
      id: '_PL7',
      'data-name': 'PL7',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "7", zone: 'Press Level', zoneId: "3" },
  };

  var _PL8 = {
    path:
      'M 205.00,830.00 C 205.00,830.00 209.71,810.00 209.71,810.00 209.71,810.00 213.43,802.02 213.43,802.02 213.43,802.02 221.00,801.00 221.00,801.00 221.00,801.00 249.00,801.00 249.00,801.00 249.00,801.00 260.40,802.02 260.40,802.02 260.40,802.02 262.00,810.00 262.00,810.00 262.00,810.00 262.00,830.00 262.00,830.00 262.00,830.00 205.00,830.00 205.00,830.00 Z',
    attr: {
      id: '_PL8',
      'data-name': 'PL8',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "8", zone: 'Press Level', zoneId: "3" },
  };

  var _PL9 = {
    path:
      'M 129.00,776.00 C 129.00,776.00 182.00,791.58 182.00,791.58 182.00,791.58 210.00,800.00 210.00,800.00 210.00,800.00 202.00,828.00 202.00,828.00 202.00,828.00 145.00,811.42 145.00,811.42 145.00,811.42 117.00,803.00 117.00,803.00 117.00,803.00 129.00,776.00 129.00,776.00 Z',
    attr: {
      id: '_PL9',
      'data-name': 'PL9',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "9", zone: 'Press Level', zoneId: "3" },
  };

  var _PL10 = {
    path:
      'M 117.00,80.00 C 117.00,80.00 158.00,67.58 158.00,67.58 158.00,67.58 202.00,55.00 202.00,55.00 202.00,55.00 210.00,83.00 210.00,83.00 210.00,83.00 153.00,99.66 153.00,99.66 153.00,99.66 138.00,104.02 138.00,104.02 138.00,104.02 130.39,105.33 130.39,105.33 130.39,105.33 125.76,99.00 125.76,99.00 125.76,99.00 117.00,80.00 117.00,80.00 Z',
    attr: {
      id: '_PL10',
      'data-name': 'PL10',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "10", zone: 'Press Level', zoneId: "3" },
  };

  var _PL11 = {
    path:
      'M 212.00,81.00 C 212.00,81.00 207.14,63.00 207.14,63.00 206.62,61.17 205.20,57.06 205.78,55.31 206.73,52.46 211.61,53.00 214.00,53.00 214.00,53.00 253.00,53.00 253.00,53.00 259.74,53.01 261.86,52.51 262.00,60.00 262.00,60.00 262.00,81.00 262.00,81.00 262.00,81.00 212.00,81.00 212.00,81.00 Z',
    attr: {
      id: '_PL11',
      'data-name': 'PL11',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "11", zone: 'Press Level', zoneId: "3" },
  };

  var _PL12 = {
    path:
      'M 264.00,53.00 C 264.00,53.00 318.00,53.00 318.00,53.00 321.39,53.00 333.00,52.10 334.98,54.60 336.23,56.19 336.00,60.01 336.00,62.00 336.00,62.00 336.00,81.00 336.00,81.00 336.00,81.00 264.00,81.00 264.00,81.00 264.00,81.00 264.00,53.00 264.00,53.00 Z',
    attr: {
      id: '_PL12',
      'data-name': 'PL12',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "12", zone: 'Press Level', zoneId: "3" },
  };


  var _PL13 = {
    path:
      'M 338.00,54.00 C 338.00,54.00 402.00,54.00 402.00,54.00 402.00,54.00 402.00,81.00 402.00,81.00 402.00,81.00 347.00,81.00 347.00,81.00 338.36,80.98 338.02,80.64 338.00,72.00 338.00,72.00 338.00,54.00 338.00,54.00 Z',
    attr: {
      id: '_PL13',
      'data-name': 'PL13',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "13", zone: 'Press Level', zoneId: "3" },
  };

  var _PL14 = {
    path:
      'M 405.00,54.00 C 405.00,54.00 478.00,54.00 478.00,54.00 478.00,54.00 478.00,81.00 478.00,81.00 478.00,81.00 405.00,81.00 405.00,81.00 405.00,81.00 405.00,54.00 405.00,54.00 Z',
    attr: {
      id: '_PL14',
      'data-name': 'PL14',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "14", zone: 'Press Level', zoneId: "3" },
  };


  var _PL15 = {
    path:
      'M 481.00,54.00 C 481.00,54.00 545.00,54.00 545.00,54.00 545.00,54.00 545.00,81.00 545.00,81.00 545.00,81.00 481.00,81.00 481.00,81.00 481.00,81.00 481.00,54.00 481.00,54.00 Z',
    attr: {
      id: '_PL15',
      'data-name': 'PL15',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "15", zone: 'Press Level', zoneId: "3" },
  };

  var _PL16 = {
    path:
      'M 547.00,81.00 C 547.00,81.00 547.00,60.00 547.00,60.00 547.04,58.05 546.90,55.37 548.60,54.02 550.19,52.77 554.01,53.00 556.00,53.00 556.00,53.00 611.00,53.00 611.00,53.00 619.64,53.02 619.98,53.36 620.00,62.00 620.00,62.00 620.00,81.00 620.00,81.00 620.00,81.00 547.00,81.00 547.00,81.00 Z',
    attr: {
      id: '_PL16',
      'data-name': 'PL16',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "16", zone: 'Press Level', zoneId: "3" },
  };


  var _PL17 = {
    path:
      'M 622.00,53.00 C 622.00,53.00 669.00,53.00 669.00,53.00 669.00,53.00 676.96,54.60 676.96,54.60 676.96,54.60 676.96,61.00 676.96,61.00 676.96,61.00 670.30,80.01 670.30,80.01 670.30,80.01 663.00,81.00 663.00,81.00 663.00,81.00 622.00,81.00 622.00,81.00 622.00,81.00 622.00,53.00 622.00,53.00 Z',
    attr: {
      id: '_PL17',
      'data-name': 'PL17',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "17", zone: 'Press Level', zoneId: "3" },
  };

  var _PL18 = {
    path:
      'M 680.00,56.00 C 689.75,56.21 703.29,61.38 713.00,64.14 713.00,64.14 766.00,80.00 766.00,80.00 766.00,80.00 757.78,98.00 757.78,98.00 757.78,98.00 753.59,104.82 753.59,104.82 753.59,104.82 747.00,104.82 747.00,104.82 747.00,104.82 732.00,100.34 732.00,100.34 732.00,100.34 673.00,83.00 673.00,83.00 673.00,83.00 680.00,56.00 680.00,56.00 ',
    attr: {
      id: '_PL18',
      'data-name': 'PL18',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "18", zone: 'Press Level', zoneId: "3" },
  };

  var _PLWESTGA = {
    path:
      'M 189.00,883.00 C 189.00,883.00 199.00,847.00 199.00,847.00 199.00,847.00 203.72,833.74 203.72,833.74 203.72,833.74 213.00,832.00 213.00,832.00 213.00,832.00 316.00,832.00 316.00,832.00 316.00,832.00 680.00,832.00 680.00,832.00 680.00,832.00 694.00,883.00 694.00,883.00 694.00,883.00 189.00,883.00 189.00,883.00 Z',
    attr: {
      id: '_PLWESTGA',
      'data-name': 'PLWESTGA',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'Press Level West General Admission', zoneId: "3" },
  };

  var _PLEASTGA = {
    path:
      'M 190.00,0.00 C 190.00,0.00 694.00,0.00 694.00,0.00 694.00,0.00 680.00,51.00 680.00,51.00 680.00,51.00 320.00,51.00 320.00,51.00 320.00,51.00 213.00,51.00 213.00,51.00 209.82,50.99 206.08,51.44 203.85,48.69 202.09,46.51 199.73,36.25 198.85,33.00 198.85,33.00 190.00,0.00 190.00,0.00 Z',
    attr: {
      id: '_PLEASTGA',
      'data-name': 'PLEASTGA',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "", zone: 'Press Level East General Admission', zoneId: "3" },
  };

  zones.push(
    _122,
    _121,
    _120,
    _119,
    _118,
    _117,
    _116,
    _115,
    _114,
    _113,
    _112,
    _111,
    _110,
    _109,
    _108,
    _107,
    _106,
    _105,
    _104,
    _103,
    _102,
    _101,
    _228,
    _227,
    _226,
    _225,
    _224,
    _223,
    _222,
    _221,
    _220,
    _219,
    _218,
    _217,
    _216,
    _215,
    _214,
    _213,
    _212,
    _211,
    _210,
    _209,
    _208,
    _207,
    _206,
    _205,
    _204,
    _203,
    _202,
    _201,
    _PL1,
    _PL2,
    _PL3,
    _PL4,
    _PL5,
    _PL6,
    _PL7,
    _PL8,
    _PL9,
    _PL10,
    _PL11,
    _PL12,
    _PL13,
    _PL14,
    _PL15,
    _PL16,
    _PL17,
    _PL18,
    _rest,
    _platinum_club,
    _PLEASTGA,
    _PLWESTGA,
  );

  return zones;
};
