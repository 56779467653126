import React from 'react';
import PropTypes from 'prop-types';
import JetsSeatMap from './JetsSeatMap';
import OilersSeatmap from './OilersSeatmap';
import SaddledomeSeatMap from './SaddledomeSeatMap';
import CanucksSeatmap from "./seatmaps/canucks";
import MapleLeafsSeatMap from './MapleLeafsSeatMap';
import SenatorsSeatMap from './SenatorsSeatMap';
import RaptorsSeatMap from './RaptorsSeatMap';
import BlueJaysSeatMap from './BlueJaysSeatMap';
import ElksSeatMap from './ElksSeatMap';
import BlueBombersSeatMap from './BlueBombersSeatMap';
import ArgonautsSeatMap from './ArgonautsSeatMap';
import StampedersSeatMap from './StampedersSeatMap';
import RedblacksSeatMap from './RedblacksSeatMap';
import LionsSeatMap from './LionsSeatMap';
import RoughridersSeatMap from './RoughridersSeatMap';
import TigercatsSeatMap from './TigercatsSeatMap';
import WranglersSeatMap from './WranglersSeatMap';
import MooseSeatMap from './MooseSeatMap';
import WhitecapsSeatMap from './WhitecapsSeatMap';
import TorontoFcSeatMap from "./TorontoFcsSeatMap";
import AbbotsfordCanucksSeatMap from './AbbotsfordCanucksSeatMap';
import BellevilleSenatorsSeatMap from "./BellevilleSenatorsSeatMap";
import CocaColaColiseumSeatMap from "./TorontoMarliesSeatMap";
import HitmenSeatMap from './HitmenSeatMap';
import WorldJuniorSeatMap from './WorldJuniorSeatMap';

const SeatMapSelector = ({ homeTeamSlug, filteredSeats, onSeatmapItemClick, zonesList }) => {
  if (homeTeamSlug === "winnipeg-jets") {
    return (
      <JetsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "edmonton-oilers") {
    return (
      <OilersSeatmap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "calgary-flames") {
    return (
      <SaddledomeSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "vancouver-canucks") {
    return (
      <CanucksSeatmap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "toronto-maple-leafs") {
    return (
      <MapleLeafsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "ottawa-senators" || homeTeamSlug === "iihf-canadian-tire-centre") {
    return (
      <SenatorsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "toronto-raptors") {
    return (
      <RaptorsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "toronto-blue-jays") {
    return (
      <BlueJaysSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "edmonton-elks") {
    return (
      <ElksSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "winnipeg-blue-bombers") {
    return (
      <BlueBombersSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "toronto-argonauts") {
    return (
      <ArgonautsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "calgary-stampeders") {
    return (
      <StampedersSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "ottawa-redblacks") {
    return (
      <RedblacksSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "bc-lions" || homeTeamSlug === "grey-cup") {
    return (
      <LionsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "saskatchewan-roughriders") {
    return (
      <RoughridersSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "hamilton-tigercats") {
    return (
      <TigercatsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "calgary-wranglers") {
    return (
      <WranglersSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "manitoba-moose") {
    return (
      <MooseSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "vancouver-whitecaps-fc") {
    return (
      <WhitecapsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "toronto-fc") {
    return (
      <TorontoFcSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "abbotsford-canucks") {
    return (
      <AbbotsfordCanucksSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "belleville-senators") {
    return (
      <BellevilleSenatorsSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "toronto-marlies") {
    return (
      <CocaColaColiseumSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "calgary-hitmen") {
    return (
      <HitmenSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else if (homeTeamSlug === "iihf-td-place") {
    return (
      <WorldJuniorSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  } else {
    return (
      <SaddledomeSeatMap
        seats={filteredSeats}
        onSeatmapItemClick={onSeatmapItemClick}
        zonesList={zonesList}
      />
    );
  }
}

SeatMapSelector.propTypes = {
  homeTeamSlug: PropTypes.string.isRequired,
  filteredSeats: PropTypes.array,
  onSeatmapItemClick: PropTypes.func.isRequired,
  zonesList: PropTypes.array.isRequired
};

SeatMapSelector.defaultProps = {
  filteredSeats: [],
};

export default SeatMapSelector;
