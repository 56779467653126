import React from "react";
import { Button, Typography, withStyles } from "@material-ui/core";

import BuyerTrustSeal from "../../images/buyertrust-seal.svg";
import TEAMS from "../../constants/Teams";

const selectorStyle = TEAMS.map(team => {
  return {
    [team.homeTeamSlug]: {
      borderRadius: 0,
      backgroundColor: "transparent",
    },
    [`${team.homeTeamSlug}.selected`]: {
      borderColor: team.colors.attribute,
      color: team.colors.attribute,
      fontWeight: "bold",
      backgroundColor: team.colors.background,
      "@media (hover: none)": {
        "&:hover": {
          backgroundColor: team.colors.background,
          borderColor: team.colors.attribute,
        },
      },
      "@media (hover: hover)": {
        "&:hover": {
          backgroundColor: "transparent",
          borderColor: team.colors.attribute,
        },
      },
    },
  }
});

const styles = (theme) => ({
  teamSelectorContainer: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
    borderTop: "1px solid #D5DEE9",
    borderBottom: "1px solid #D5DEE9",
    [theme.breakpoints.down("xs")]: {
      "& div": {
        flexBasis: "20%",
        flex: "1",
      },
    },
    // [theme.breakpoints.down("xs")]: {
    //   overflow:"auto",
    //   justifyContent:"flex-start",
    //   "&::-webkit-scrollbar": {
    //     display: "none"
    //   },
    //   "-ms-overflow-style": "none",  /* IE and Edge */
    //   scrollbarWidth: "none",  /* Firefox */
    // }
  },
  teamButton: {
    flex: 1,
    borderRadius: 0,
    border: "3px solid rgba(255,255,255,0)",
    height: "100%",
    fontWeight: 400,
    textTransform: "none",
    flexBasis: "30%",
    "&:disabled": {
      border: "3px solid rgba(255,255,255,0)",
      color: "rgba(0,0,0,0.2)",
    },
  },
  ...Object.assign({}, ...selectorStyle ),
  leagueButton: {
    borderRadius: 0,
    backgroundColor: "transparent",
  },
  "leagueButton.selected": {
    borderColor: "#7c0519",
    color: "#e8e5e6",
    fontWeight: "bold",
    backgroundColor: "rgba(180, 0, 29)",
    "@media (hover:none)": {
      "&:hover": {
        backgroundColor: "rgba(180, 0, 29)",
        borderColor: "#7c0519",
      },
    },
    "@media (hover: hover)": {
      "&:hover": {
        backgroundColor: "rgba(180, 0, 29, 0.7)",
        borderColor: "#7c0519",
      },
    },
  },
  finePrint: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D5DEE9",
  },
  [theme.breakpoints.up("md")]: {
    teamSelectorContainer: {
      borderTop: "1px solid #D5DEE9",
    },
    teamButton: {
      minWidth: theme.spacing.unit * 20,
      fontSize: "1.5rem",
      padding: "1rem",
    },
    finePrint: {
      padding: "1rem",
    },
  },
  [theme.breakpoints.down("md")]: {
    teamSelectorContainer: {
      boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.05)",
    },
    teamButton: {
      minWidth: theme.spacing.unit * 8,
      padding: ".75rem",
    },
    finePrint: {
      padding: ".5rem",
    },
  },
});

export const teams = [
  {
    parentSlug: "NHL",
    teams: TEAMS.filter(team => team.type === "NHL")    
  },
  {
    parentSlug: "NBA",
    teams: TEAMS.filter(team => team.type === "NBA")
  },
  {
    parentSlug: "MLB",
    teams: TEAMS.filter(team => team.type === "MLB")
  },
  {
    parentSlug: "CFL",
    teams: TEAMS.filter(team => team.type === "CFL")
  },
  {
    parentSlug: "AHL",
    teams: TEAMS.filter(team => team.type === "AHL")
  },
  {
    parentSlug: "MLS",
    teams: TEAMS.filter(team => team.type === "MLS")
  },
  {
    parentSlug: "WHL",
    teams: TEAMS.filter(team => team.type === "WHL")
  },
  {
    parentSlug: "IIHF",
    teams: TEAMS.filter(team => team.type === "IIHF")
  },
];

const TeamSelector = ({
  classes,
  selectedTeam,
  selectedParent,
  onTeamSelection,
  isLoadingGames,
  onParentSelection,
}) => {
  const handleSelection = ({ homeTeamSlug }) => {
    if (!isLoadingGames) {
      onTeamSelection({ homeTeamSlug });
    }
  };

  const handleParentSelection = ({ parentSlug }) => {
    if (!isLoadingGames) {
      onParentSelection({ parentSlug });
    }
  };
  const matchedTeam = teams.find((team) => team.parentSlug === selectedParent);
  const filteredTeams = matchedTeam ? matchedTeam.teams : [];

  return (
    <React.Fragment>
      <div className={classes.teamSelectorContainer}>
        {teams.map(({ parentSlug }) => {
          return (
            <div key={parentSlug}>
              <Button
                size="large"
                variant="text"
                className={`${classes.teamButton} ${classes.leagueButton} ${
                  selectedParent === parentSlug
                    ? `${classes["leagueButton.selected"]}`
                    : ""
                }`}
                fullWidth={true}
                onClick={() => handleParentSelection({ parentSlug })}
                disabled={isLoadingGames}
              >
                {parentSlug}
              </Button>
            </div>
          );
        })}
      </div>
      <div className={classes.teamSelectorContainer}>
        {filteredTeams.map(({ name, homeTeamSlug }) => {
          return (
            <div key={homeTeamSlug}>
              <Button
                size="large"
                variant="text"
                className={`${classes.teamButton} ${classes[homeTeamSlug]} ${
                  selectedTeam === homeTeamSlug
                    ? `${classes[homeTeamSlug + ".selected"]}`
                    : ""
                }`}
                fullWidth={true}
                onClick={() => handleSelection({ homeTeamSlug })}
                disabled={isLoadingGames}
              >
                {name}
              </Button>
            </div>
          );
        })}
      </div>
      <div className={classes.finePrint}>
        <img src={BuyerTrustSeal} style={{ marginRight: ".5rem" }} />
        <Typography
          variant="caption"
          align="center"
          color="textSecondary"
          padding="0"
        >
          <strong>100% Guaranteed</strong>. All Prices CAD.
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(TeamSelector);
