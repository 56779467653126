import React, { useState, useEffect } from 'react';
import { API } from "aws-amplify";
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, Grid } from '@material-ui/core';
import Illustration from '../images/banners/under-maintenance.png';
import Helmet from "react-helmet";
import validator from "validator";
import moment from "moment-timezone";
import cdialog from "../components/cdialog";

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '0 20px',
  },
  header: {
    marginBottom: theme.spacing.unit * 2,
    fontWeight: 'bold',
    color: '#00a0ff',
  },
  subheader: {
    marginTop: theme.spacing.unit * 2,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subtext: {
    marginTop: theme.spacing.unit * 2,
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  containerImage: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing.unit * 3,
  },
  bannerImage: {
    width: "auto",
    height: "240px",
  },
  countdownContainer: {
    margin: '30px 0',
  },
  countdownBox: {
    width: 80,
    height: 80,
    borderRadius: 8,
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  countdownNumber: {
    fontWeight: 'bold',
    color: '#00a0ff',
  },
  countdownLabel: {
    color: '#666',
  },
  subscriptionContainer: {
    maxWidth: 680,
    width: '100%',
  },
  textField: {
    width: '80%',
    marginBottom: '.5rem',
    marginLeft: '6.8rem',
    padding: '.5rem',
    borderRadius: '4px',
    border: '1px solid #e5e5ea',
    justifyContent: 'flex-end'
  },
  textFieldError: {
    border: '1px solid rgba(255, 23, 68, 0.5)',
    backgroundColor: 'rgba(255, 23, 68, 0.05)',
  },
  errorText: {
    color: 'red',
    fontSize: '0.95rem',
  },
  inputEmail: {
    padding: '0.2rem',
  },
  notifyButton: {
    marginTop: theme.spacing.unit,
    marginRight: '6rem',
    padding: '12px 24px',
    textTransform: 'none',
  },
  [theme.breakpoints.down("md")]: {
    textField: {
      marginLeft: '0px',
      width: '100%',
    },
    notifyButton: {
      marginLeft: '.2rem',
    },
    containerImage: {
      marginTop: theme.spacing.unit * 8,
    }
  },
  [theme.breakpoints.up("sm")]: {
    textField: {
      width: '80%',
      marginLeft: '6rem',
    },
    notifyButton: {
      marginLeft: '.2rem',
    }
  },
});

const Maintenance = ({ classes }) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationSent, setNotificationSent] = useState(false);

  useEffect(() => {
    // Maintenance Period in MST
    const maintenanceEnd = moment.tz("2024-11-22 03:00:00", "America/Edmonton");
  
    const updateCountdown = () => {
      const now = moment();
      const difference = maintenanceEnd.diff(now);
  
      if (difference > 0) {
        const duration = moment.duration(difference);
        setTimeLeft({
          hours: Math.floor(duration.asHours()),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      } else {
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
      }
    };
  
    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  // Email Notification
  const validateEmail = (email) => validator.isEmail(email);
  
  const handleNotifyMe = async () => {
    if (!validateEmail(email)) {
      setIsValid(false);
      return;
    }
  
    setIsLoading(true);
    setIsValid(true);
  
    try {
      await API.post("v2", "communication/email/maintenance", {
        body: {
          email: email,
        },
      });
  
      setNotificationSent(true);
      cdialog.success("Notification sent", <span>Thank you for subscribing.</span>);
    } catch (error) {
      console.error('Error sending email:', error);
      cdialog.error("Failed to send!", <span>Please try again later.</span>);
    } finally {
      setIsLoading(false);
    }
  };
  
  const currentURL = window.location.href;
  return (
    <main className={classes.root}>
      <Helmet>
        <title>Maintenance</title>
        <meta name="description" content="Under maintenance. Will be back shortly" />
        <meta name="keywords" content={`maintenance, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Maintenance" />
        <meta property="og:description" content="Under maintenance. Will be back shortly" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentURL} />
        <link rel="canonical" href={currentURL} />
      </Helmet>
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <div className={classes.containerImage}>
            <img
              src={Illustration}
              className={classes.bannerImage}
              alt="FansFirst Banner"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Typography variant="title" gutterBottom className={classes.header}>
            Under Maintenance!
          </Typography>
          <Typography variant="subtitle1" className={classes.subheader}>
            We are currently performing maintenance on our Checkout system.
          </Typography>
          <Typography variant="title" className={classes.subtext}>
            <strong>Maintenance Period:</strong> Nov 22, 2024 12:00 AM–3:00 AM MST
          </Typography>
          <Typography variant="subtitle1" className={classes.subheader}>
            Customers will be able to continue using the site and manage their listings.<br />
            Customers who use the Checkout Page functionality may experience an interruption <br />
            in service between 12:00 AM and 3:00 AM MST.
          </Typography>
          <Typography variant="subtitle1" className={classes.subheader}>
            Sorry for the inconvenience.
          </Typography>
        </Grid>

        {/* Countdown Section */}
        <Grid container spacing={8} justify="center" className={classes.countdownContainer}>
          {Object.entries(timeLeft).map(([label, value]) => (
            <Grid item key={label}>
              <div className={classes.countdownBox}>
                <Typography variant="h4" className={classes.countdownNumber}>
                  {String(value).padStart(2, '0')} {/* Add leading zero if needed */}
                </Typography>
                <Typography variant="caption" className={classes.countdownLabel}>
                  {label.charAt(0).toUpperCase() + label.slice(1)}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Email Subscription Section */}
      <Grid container spacing={2} justify="center" className={classes.subscriptionContainer}>
        <Grid item xs={9} sm={9} md={8} lg={8}>
          <TextField
            id="email"
            placeholder="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
            className={`${classes.textField} ${!isValid && classes.textFieldError}`}
            disabled={isLoading || notificationSent}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.inputEmail },
            }}
          />
          {!isValid && (
            <Typography className={classes.errorText}>Please enter a valid email address.</Typography>
          )}
        </Grid>
        <Grid item xs={3} sm={3} md={4} lg={4}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleNotifyMe}
            className={classes.notifyButton}
            disabled={isLoading || notificationSent}
          >
            {notificationSent ? 'Sent' : 'Notify Me'}
          </Button>
        </Grid>
      </Grid>
    </main>
  );
};

export default withStyles(styles)(Maintenance);
