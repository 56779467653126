import React, { useEffect, useState } from "react";
import { Button, Typography, withStyles, MobileStepper } from "@material-ui/core";
import BlogCard from "./blogCard";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IconButton } from "@material-ui/core";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => ({
  container: {
    position: "relative"
  },
  contentContainer: {
    position: "relative",
  },
  buttonContainer: {
    position: "absolute",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    top: "50%",
    padding: "0 20px",
    "transform": "translateY(-50%)",
    "-ms-transform": "translateY(-50%)",
    "-moz-transform": "translateY(-50%)",
    "-webkit-transform": "translateY(-50%)",
    "-o-transform": "translateY(-50%)"
  },
  button: {
    color: "white",
    backgroundColor: "#00A0FF",
    boxShadow: "rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;",
    "&:hover": {
      backgroundColor: "#028fe3",
    },
    "&:disabled": {
      backgroundColor: "rgba(100, 100, 100, 0.2)",
      color: "white",
      boxShadow: "rgba(100, 100, 111, 0) 0px 7px 29px 0px;",
    }
  },
  card: {
    padding: "0 40px"
  },
  swiper: {
    "& div": {
      alignItems: "center",
      "& div": {
        paddingTop: "20px",
        paddingBottom: "20px"
      }
    },
  }
});

const MobileLatestBlog = ({
  classes,
  blogUrls
}) => {
  const [blogs, setBlogs] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = blogs.length;

  function renderButtonContainer() {
    return (
      <div className={classes.buttonContainer}>
        {renderPrevButton()}
        {renderNextButton()}
      </div>
    )
  }

  function renderPrevButton() {
    return (
      <IconButton className={classes.button} onClick={handleBack} disabled={(activeStep === 0)}>
        <ChevronLeftIcon></ChevronLeftIcon>
      </IconButton>
    )
  }

  function renderNextButton() {
    return (
      <IconButton className={classes.button} onClick={handleNext} disabled={(activeStep === maxSteps - 1)}>
        <ChevronRightIcon></ChevronRightIcon>
      </IconButton>
    )
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  useEffect(() => {
    let newArray = []
    blogUrls.forEach((url) => {
      newArray.push(<BlogCard source={url}></BlogCard>)
    })
    setBlogs(newArray);
  }, [])

  if (!blogs) {
    return (
      null
    )
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Typography
          variant="title"
          align="center"
          color="error"
          style={{
            marginTop: "2rem",
            fontSize: "2rem",
            fontWeight: "bold",
            marginBottom: "0"
          }}
        >
          <span>LATEST BLOG POSTS</span>
        </Typography>
        <div className={classes.contentContainer}>
          <AutoPlaySwipeableViews
            axis='x'
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            className={classes.swiper}
          >
            {blogs.map((blog, index) => (
              <div key={index} className={classes.card}>
                {Math.abs(activeStep - index) <= 2 ? (
                  blog
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          {renderButtonContainer()}
        </div>
      </div>
      <MobileStepper
        steps={maxSteps}
        activeStep={activeStep}
        style={{ justifyContent: "center", position: "relative" }}
        nextButton={renderNextButton()}
        backButton={renderPrevButton()}
      />
      <div style={{
        textAlign: "center",
        marginTop: "20px",
        padding: "20px",
        borderTop: "1px solid #D5DEE9",
        borderBottom: "1px solid #D5DEE9",
      }}>
        <Button
          variant="text"
          href={`/blogs`}
          color="primary"
          style={{ textTransform: "none" }}
        >
          See All Blog Posts
        </Button>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(MobileLatestBlog);
