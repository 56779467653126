const normalizedZoneMap = {
  "calgary-flames": {
    "Press Level": "pressLevel",
    "Second Level": "secondLevel",
    "Lower Club": "lowerBowl",
    "Lower Bowl": "lowerBowl",
  },
  "vancouver-canucks": {
    "Upper Bowl": "pressLevel",
    "Upper Bowl - No Alcohol": "pressLevel",
    "Upper Bowl - Balcony": "pressLevel",
    "WC 311": "pressLevel",
    "WC 317": "pressLevel",
    "WC 400E": "pressLevel",
    "WC 400S": "pressLevel",
    "Lower Bowl": "secondLevel",
    "Lower Bowl - No Alcohol": "secondLevel",
    "Club Lower": "lowerBowl",
    "Sports Bar": "lowerBowl",
  },
  "winnipeg-jets": {
    "Upper Zone": "pressLevel",
    Loge: "lowerBowl",
    "Middle Zone": "secondLevel",
    "Lower Zone": "lowerBowl",
    "PREMIUM SUITE LOUNGE": "secondLevel",
  },
  "edmonton-oilers": {
    "Upper Zone": "pressLevel",
    "Upper Bowl": "pressLevel",
    Loge: "lowerBowl",
    "Lower Zone": "secondLevel",
    "Lower Bowl": "secondLevel",
    "Lower Club": "secondLevel",
    Other: "lowerBowl",
    "Sky Lounge AA": "lowerBowl",
    "Sky Lounge BB": "lowerBowl",
    "Sky Lounge CC": "lowerBowl",
    "Sky Lounge DD": "lowerBowl",
    "Sky Lounge EE": "lowerBowl",
    "Sky Lounge A": "lowerBowl",
    "Sky Lounge B": "lowerBowl",
    "Sky Lounge C": "lowerBowl",
    "Sky Lounge D": "lowerBowl",
    "Sky Lounge E": "lowerBowl",
    "Sportsnet Club AA": "lowerBowl",
    "Sportsnet Club BB": "lowerBowl",
    "Sportsnet Club CC": "lowerBowl",
    "Sportsnet Club DD": "lowerBowl",
    "Sportsnet Club EE": "lowerBowl",
    "Sportsnet Club A": "lowerBowl",
    "Sportsnet Club B": "lowerBowl",
    "Sportsnet Club C": "lowerBowl",
    "Sportsnet Club D": "lowerBowl",
    "Sportsnet Club E": "lowerBowl",
  },
  "toronto-maple-leafs": {
    "Upper Zone": "pressLevel",
    "Lower Zone": "secondLevel",
    Suite: "lowerBowl",
    "Suite A": "lowerBowl",
    GBOX: "lowerBowl",
    Molson: "lowerBowl",
  },
  "ottawa-senators": {
    "Upper Zone": "pressLevel",
    "Middle Zone": "secondLevel",
    "Lower Zone": "lowerBowl",
    Suite: "pressLevel",
    "Suite A": "pressLevel",
  },
  "toronto-raptors": {
    "Upper Zone": "pressLevel",
    "Lower Zone": "secondLevel",
    Suite: "lowerBowl",
    "Suite A": "lowerBowl",
    GBOX: "lowerBowl",
    Molson: "lowerBowl",
    "Courtside North": "lowerBowl",
    "Courtside East": "lowerBowl",
    "Courtside South": "lowerBowl",
    "Courtside West": "lowerBowl",
    Courtside: "lowerBowl",
  },
  "toronto-blue-jays": {
    "Upper Zone": "pressLevel",
    "Upper Zone A": "pressLevel",
    "Upper Zone B": "pressLevel",
    "CORONA ROOFTOP PATIO": "pressLevel",
    "TD PARK SOCIAL": "pressLevel",
    "HOME RUN ZONE": "secondLevel",
    "SCHNEIDERS PORCH": "secondLevel",
    "TM LOUNGE": "secondLevel",
    "WESTJET FLIGHT DECK": "secondLevel",
    "Middle Zone": "secondLevel",
    "Middle Zone AL": "secondLevel",
    "Middle Zone L": "secondLevel",
    "Middle Zone R": "secondLevel",
    "Middle Zone AR": "secondLevel",
    "Middle Zone BR": "secondLevel",
    "Middle Zone BL": "secondLevel",
    "Middle Zone W": "secondLevel",
    "LEFT FIELD BALCONY": "lowerBowl",
    "RIGHT FIELD BLEACHERS": "lowerBowl",
    "ROGERS LANDING": "lowerBowl",
    "THE CATCH BAR": "lowerBowl",
    "THE STOP": "lowerBowl",
    "Lower Zone": "lowerBowl",
    "Lower Zone W": "lowerBowl",
    "Lower Zone A": "lowerBowl",
    "Lower Zone B": "lowerBowl",
    "Lower Zone C": "lowerBowl",
    "Lower Zone D": "lowerBowl",
    "Lower Zone L": "lowerBowl",
    "Lower Zone R": "lowerBowl",
    "Lower Zone AL": "lowerBowl",
    "Lower Zone AR": "lowerBowl",
    "Lower Zone BL": "lowerBowl",
    "Lower Zone BR": "lowerBowl",
    "Lower Zone CL": "lowerBowl",
    "Lower Zone CR": "lowerBowl",
    "Lower Zone DL": "lowerBowl",
    "Lower Zone DR": "lowerBowl",
    "Lower Zone WR": "lowerBowl",
    "Lower Zone WL": "lowerBowl",
  },
  "edmonton-elks": {
    "Upper Zone A": "pressLevel",
    "Upper Zone B": "pressLevel",
    "Upper Zone C": "pressLevel",
    "Upper Zone D": "pressLevel",
    "Upper Zone E": "pressLevel",
    "Upper Zone F": "pressLevel",
    "Upper Zone G": "pressLevel",
    "Upper Zone H": "pressLevel",
    "Upper Zone I": "pressLevel",
    "Upper Zone J": "pressLevel",
    "Upper Zone K": "pressLevel",
    "Upper Zone L": "pressLevel",
    "Upper Zone M": "pressLevel",
    "Upper Zone N": "pressLevel",
    "Upper Zone O": "pressLevel",
    "Upper Zone P": "pressLevel",
    "Upper Zone Q": "pressLevel",
    "Upper Zone R": "pressLevel",
    "Upper Zone T": "pressLevel",
    "Upper Zone U": "pressLevel",
    "Upper Zone V": "pressLevel",
    "Upper Zone W": "pressLevel",
    "Upper Zone X": "pressLevel",
    "Upper Zone Y": "pressLevel",
    "Upper Zone Z": "pressLevel",
    "Upper Zone AA": "pressLevel",
    "Upper Zone BB": "pressLevel",
    "Upper Zone CC": "pressLevel",
    "Upper Zone DD": "pressLevel",
    "Upper Zone EE": "pressLevel",
    "Upper Zone FF": "pressLevel",
    "Upper Zone MM": "pressLevel",
    "Upper Zone NN": "pressLevel",
    "Upper Zone OO": "pressLevel",
    "Upper Zone PP": "pressLevel",
    "Upper Zone QQ": "pressLevel",
    "Upper Zone RR": "pressLevel",
    "Lower Zone A": "lowerBowl",
    "Lower Zone B": "lowerBowl",
    "Lower Zone C": "lowerBowl",
    "Lower Zone D": "lowerBowl",
    "Lower Zone E": "lowerBowl",
    "Lower Zone F": "lowerBowl",
    "Lower Zone G": "lowerBowl",
    "Lower Zone H": "lowerBowl",
    "Lower Zone I": "lowerBowl",
    "Lower Zone J": "lowerBowl",
    "Lower Zone K": "lowerBowl",
    "Lower Zone L": "lowerBowl",
    "Lower Zone M": "lowerBowl",
    "Lower Zone N": "lowerBowl",
    "Lower Zone O": "lowerBowl",
    "Lower Zone P": "lowerBowl",
    "Lower Zone Q": "lowerBowl",
    "Lower Zone R": "lowerBowl",
    "Lower Zone T": "lowerBowl",
    "Lower Zone U": "lowerBowl",
    "Lower Zone V": "lowerBowl",
    "Lower Zone W": "lowerBowl",
    "Lower Zone X": "lowerBowl",
    "Lower Zone Y": "lowerBowl",
    "Lower Zone Z": "lowerBowl",
    "Lower Zone AA": "lowerBowl",
    "Lower Zone BB": "lowerBowl",
    "Lower Zone CC": "lowerBowl",
    "Lower Zone DD": "lowerBowl",
    "Lower Zone EE": "lowerBowl",
    "Lower Zone FF": "lowerBowl",
    "Lower Zone GG": "lowerBowl",
    "Lower Zone HH": "lowerBowl",
    "Lower Zone JJ": "lowerBowl",
    "Lower Zone KK": "lowerBowl",
    "Lower Zone LL": "lowerBowl",
    "Lower Zone MM": "lowerBowl",
    "Lower Zone NN": "lowerBowl",
    "Lower Zone OO": "lowerBowl",
    "Lower Zone PP": "lowerBowl",
    "Lower Zone QQ": "lowerBowl",
    "Lower Zone RR": "lowerBowl",
    "Twisted Tea Party Deck": "secondLevel",
    "Coors Light Party Deck": "secondLevel",
    "Rooftop Patio": "secondLevel",
  },
  "winnipeg-blue-bombers": {
    "Upper Zone": "pressLevel",
    "Lower Zone": "lowerBowl",
    "Jim Beam Social": "secondLevel",
  },
  "toronto-argonauts": {
    "Upper Zone": "pressLevel",
    "Upper Zone A": "pressLevel",
    "Middle Zone": "secondLevel",
    "Lower Zone": "lowerBowl",
    "Lower Zone A": "lowerBowl",
  },
  "calgary-stampeders": {
    "Lower Zone A": "lowerBowl",
    "Lower Zone B": "lowerBowl",
    "Lower Zone C": "lowerBowl",
    "Lower Zone D": "lowerBowl",
    "Lower Zone E": "lowerBowl",
    "Lower Zone F": "lowerBowl",
    "Lower Zone G": "lowerBowl",
    "Lower Zone H": "lowerBowl",
    "Lower Zone I": "lowerBowl",
    "Lower Zone J": "lowerBowl",
    "Lower Zone K": "lowerBowl",
    "Lower Zone L": "lowerBowl",
    "Lower Zone M": "lowerBowl",
    "Lower Zone N": "lowerBowl",
    "Lower Zone O": "lowerBowl",
    "Lower Zone P": "lowerBowl",
    "Lower Zone Q": "lowerBowl",
    "Lower Zone R": "lowerBowl",
    "Lower Zone T": "lowerBowl",
    "Lower Zone U": "lowerBowl",
    "Lower Zone V": "lowerBowl",
    "Lower Zone W": "lowerBowl",
    "Lower Zone X": "lowerBowl",
    CLUBHOUSE: "secondLevel",
  },
  "ottawa-redblacks": {
    "Upper Zone AA": "pressLevel",
    "Upper Zone BB": "pressLevel",
    "Upper Zone CC": "pressLevel",
    "Upper Zone DD": "pressLevel",
    "Upper Zone EE": "pressLevel",
    "Upper Zone FF": "pressLevel",
    "Upper Zone GG": "pressLevel",
    "Upper Zone HH": "pressLevel",
    "Upper Zone II": "pressLevel",
    "Upper Zone JJ": "pressLevel",
    "Upper Zone NN": "pressLevel",
    "Upper Zone OO": "pressLevel",
    "Upper Zone PP": "pressLevel",
    "Upper Zone QQ": "pressLevel",
    "Upper Zone RR": "pressLevel",
    "Upper Zone SS": "pressLevel",
    "Upper Zone TT": "pressLevel",
    "Upper Zone UU": "pressLevel",
    "Upper Zone VV": "pressLevel",
    "Upper Zone WW": "pressLevel",
    "Upper Zone XX": "pressLevel",
    "Middle Zone": "secondLevel",
    "Lower Zone A": "lowerBowl",
    "Lower Zone B": "lowerBowl",
    "Lower Zone C": "lowerBowl",
    "Lower Zone D": "lowerBowl",
    "Lower Zone E": "lowerBowl",
    "Lower Zone F": "lowerBowl",
    "Lower Zone G": "lowerBowl",
    "Lower Zone H": "lowerBowl",
    "Lower Zone I": "lowerBowl",
    "Lower Zone J": "lowerBowl",
    "Lower Zone K": "lowerBowl",
    "Lower Zone L": "lowerBowl",
    "Lower Zone M": "lowerBowl",
    "Lower Zone N": "lowerBowl",
    "Lower Zone O": "lowerBowl",
    "Lower Zone P": "lowerBowl",
    "Lower Zone Q": "lowerBowl",
    "Lower Zone R": "lowerBowl",
    "Lower Zone T": "lowerBowl",
    "Lower Zone U": "lowerBowl",
    "Lower Zone V": "lowerBowl",
    "Lower Zone W": "lowerBowl",
    "Lower Zone X": "lowerBowl",
    "Lower Zone Y": "lowerBowl",
    "Lower Zone Z": "lowerBowl",
    Field: "lowerBowl",
  },
  "bc-lions": {
    "Upper Zone": "pressLevel",
    "Middle Zone": "secondLevel",
    "Club Seats": "secondLevel",
    "Lower Zone": "lowerBowl",
    "The Den": "lowerBowl",
  },
  "saskatchewan-roughriders": {
    "Upper Zone": "pressLevel",
    "Middle Zone": "secondLevel",
    "Middle Zone A": "secondLevel",
    "Lower Zone": "lowerBowl",
    "PIL COUNTRY - STANDING ROOM ONLY": "lowerBowl"
  },
  "hamilton-tigercats": {
    "Upper Zone": "pressLevel",
    "Premium Zone C": "secondLevel",
    "Lower Zone": "lowerBowl",
    "The Striply": "lowerBowl",
    "Touchdown Lounge": "lowerBowl",
    "Stelco Northend": "lowerBowl",
  },
  "grey-cup": {
    "Upper Zone": "pressLevel",
    "Middle Zone": "secondLevel",
    "Club Seats": "secondLevel",
    "Lower Zone": "lowerBowl",
    "The Den": "lowerBowl",
  },
  "calgary-wranglers": {
    "Press Level": "pressLevel",
    "Second Level": "secondLevel",
    "Lower Club": "lowerBowl",
    "Lower Bowl": "lowerBowl",
  },
  "manitoba-moose": {
    "Upper Zone": "pressLevel",
    Loge: "lowerBowl",
    "Middle Zone": "secondLevel",
    "Lower Zone": "lowerBowl",
    "PREMIUM SUITE LOUNGE": "secondLevel",
  },
  "vancouver-whitecaps-fc": {
    "General Admission": "pressLevel",
    "Middle Zone": "secondLevel",
    "Lower Zone": "lowerBowl",
  },
  "toronto-fc": {
    "Upper Zone": "pressLevel",
    "Upper Zone A": "pressLevel",
    "Lower Zone": "secondLevel",
    "Lower Zone A": "secondLevel",
    "Side Seats": "lowerBowl",
  },
  "abbotsford-canucks": {
    "Lower Zone": "lowerBowl",
    "Lower Zone A": "lowerBowl",
    "Lower Zone B": "lowerBowl",
    "ADA": "lowerBowl",
  },
  "belleville-senators": {
    "Upper Zone": "pressLevel",
    "Lower Zone": "secondLevel",
    "Lower Zone L": "secondLevel",
    "Lower Zone Box": "secondLevel",
    "Fan Zone": "lowerBowl",
    "Mezzanine": "lowerBowl",
    "North": "lowerBowl",
    "South": "lowerBowl"
  },
  "toronto-marlies": {
    "Lower Zone": "secondLevel"
  },
  "calgary-hitmen": {
    "Press Level East General Admission": "pressLevel",
    "Press Level West General Admission": "pressLevel",
    "Press Level": "pressLevel",
    "Second Level": "secondLevel",
    "Lower Club": "lowerBowl",
    "Lower Bowl": "lowerBowl",
  },
  "iihf-canadian-tire-centre": {
    "Upper Zone": "pressLevel",
    "Middle Zone": "secondLevel",
    "Lower Zone": "lowerBowl",
    Suite: "pressLevel",
    "Suite A": "pressLevel",
  },
  "iihf-td-place": {
    "Lower Zone": "lowerBowl"
  }
};

export default normalizedZoneMap;
