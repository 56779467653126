import React, { Component } from 'react';
import {
  withStyles, 
  FormHelperText,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Slide,
} from '@material-ui/core';
import validator from "validator";
import { Auth } from "aws-amplify";
import cdialog from "../components/cdialog";

const styles = (theme) => ({
  loginDialog: {
    maxWidth: "36rem",
    minWidth: "36rem",
    margin: "auto",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    formContainer: {
      padding: "0 1rem",
    },
  },
  textField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  button: {
    padding: ".875rem",
    marginTop: ".5rem",
  },
});

const Transition = (props) => {
    return <Slide direction="down" {...props} />;
  }
class LoginForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isValidEmail: true,
      isValidPassword: true,
    };

    this.isValidEmail = this.isValidEmail.bind(this);
    this.isValidPassword = this.isValidPassword.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  
  handleChangeEmail = (event) => {
    this.setState({
      [event.target.id]: String(event.target.value).toLowerCase(),
    });
  };

  isValidEmail(email) {
    const isValidEmail = validator.isEmail(email);
    this.setState({ isValidEmail });

    return isValidEmail;
  }

  isValidPassword(password) {
    const isValidPassword = !validator.isEmpty(password);
    this.setState({ isValidPassword });

    return isValidPassword;
  }

  isValidLogin(email, pw) {
    const isValidEmail = this.isValidEmail(email);
    const isValidPassword = this.isValidPassword(pw);
    return isValidEmail && isValidPassword;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    try {
      if (this.isValidLogin(email, password)) {
        this.setState({ isSubmitting: true });
        const { setUserNeedNewPassword, userHasAuthenticated, setUserAttributes, history } = this.props;
        const user = await Auth.signIn(email.toLowerCase().trim(), password);

        switch (user.challengeName) {
          case "NEW_PASSWORD_REQUIRED": {
            setUserNeedNewPassword(user);
            history.push("/new-password");
            break;
          }
          default: {
            const currUser = await Auth.currentAuthenticatedUser();
            const currentRoute = this.props.match.path;
            const onCheckoutPage = currentRoute === "/seats/:slug/:gameId/:seatId";

            userHasAuthenticated(true);
            setUserAttributes(currUser);
            this.setState({ isSubmitting: false });

            if (onCheckoutPage) {
               window.location.reload();
            } else {
              history.push("/dashboard");
            }
          }
        }
      }
    } catch (e) {
      switch (e.code) {
        case "NotAuthorizedException": {
          const msg = e.message.toLowerCase().includes("attempts exceeded")
            ? <span>Login attempts exceeded. Please try again later.</span> 
            : <span>The email or password you entered is incorrect. Please try again or contact support by text at <a href='tel:1-403-768-2298' style={{ color: "#2196f3", textDecoration: "none" }}>1-403-768-2298</a> or email at <a href='mailto:tix@fansfirst.ca' style={{ color: "#2196f3", textDecoration: "none" }}>tix@fansfirst.ca</a> for assistance.</span>
          cdialog.error("Login failed!", msg);
          break;
        }
        case "UserNotFoundException": {
          cdialog.error("Login failed!", <span>The email is not in our system. Please <a href='/signup' style={{ color: "#2196f3", textDecoration: "none" }}>sign up</a> to create a new account.</span>);
          break;
        }
        default: {
          cdialog.error("Login failed!", <span>The email or password you entered is incorrect. Please try again or contact support by text at <a href='tel:1-403-768-2298' style={{ color: "#2196f3", textDecoration: "none" }}>1-403-768-2298</a> or email at <a href='mailto:tix@fansfirst.ca' style={{ color: "#2196f3", textDecoration: "none" }}>tix@fansfirst.ca</a> for assistance.</span>);
        }
      }
      this.setState({ isSubmitting: false });
    }
  };

  renderForm() {
    const { classes } = this.props;
    const { isSubmitting } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          autoFocus={true}
          className={
            this.state.isValidEmail ? classes.textField : classes.textFieldError
          }
          disabled={isSubmitting}
          error={true}
          id="email"
          placeholder="Email Address"
          name="email"
          type="email"
          fullWidth={true}
          value={this.state.email}
          onChange={this.handleChangeEmail}
          margin="dense"
          InputProps={{
            disableUnderline: true,
          }}
        />
        {!this.state.isValidEmail && (
          <FormHelperText error={true}>
            Please enter your email address
          </FormHelperText>
        )}

        <TextField
          className={
            this.state.isValidPassword
              ? classes.textField
              : classes.textFieldError
          }
          disabled={isSubmitting}
          error={true}
          id="password"
          placeholder="Password"
          name="password"
          type="password"
          fullWidth={true}
          value={this.state.password}
          onChange={this.handleChange}
          margin="dense"
          InputProps={{
            disableUnderline: true,
          }}
        />
        {!this.state.isValidPassword && (
          <FormHelperText error={true}>
            Please enter your password
          </FormHelperText>
        )}

        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitting}
          variant="contained"
          fullWidth={true}
          onClick={this.handleSubmit}
          type="submit"
        >
          Login
        </Button>
        <Button
          className={classes.button}
          color="secondary"
          disabled={isSubmitting}
          variant="text"
          fullWidth={true}
          href="/forgot-password"
          type="submit"
        >
          Forgot Password
        </Button>
        <Typography variant="caption" color="textSecondary" align="center">
          Don't have an account{" "}
          <a
            style={{ color: "#2196f3", textDecoration: "none" }}
            href="/signup"
          >
            Sign up here!
          </a>
        </Typography>
      </form>
    );
  }

  render() {
    const { isOpen, handleClose, classes } = this.props;

    return (
      <Dialog 
        TransitionComponent={Transition}
        fullWidth={true}
        className={classes.loginDialog}
        open={isOpen} 
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
          <Grid
            className={classes.formContainer}
            container
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} md={12}>
              {this.renderForm()}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(LoginForm);
