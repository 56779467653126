import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, withStyles } from "@material-ui/core";
import { API } from "aws-amplify";
import BlogCard from "./blogCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import DesktopLatestBlogs from "./DesktopLatestBlogs";
import MediaQuery from 'react-responsive'
import MobileLatestBlog from "./MobileLatestBlog";

const Posts = ({
  classes
 }) => {
  const [blogUrls, setBlogUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    fetchUrls()
  }, []);

  async function fetchUrls() {
    try {
      const latestBlogUrls = await API.get(
        "v2",
        `blogs/get/latest`
      );
      setBlogUrls(latestBlogUrls)
      setLoading(false)
    } catch (err) { console.log('error fetching blog URLs: ', err) }
  }

  if (loading){
    return (
      <React.Fragment>
        <Typography
          variant="title"
          align="center"
          color="error"
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          <span>LATEST BLOG POSTS</span>
        </Typography>
        <Typography
          align="center"
          variant="body2"
          style={{ marginTop: "2rem" }}
        >
          <CircularProgress
            color="inherit"
            style={{ width: 16, height: 16, marginRight: 16 }}
          />
          Loading blogs...
        </Typography>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <MediaQuery minWidth={1280}>
        <DesktopLatestBlogs blogUrls={blogUrls}></DesktopLatestBlogs>
      </MediaQuery>
      <MediaQuery maxWidth={1279}>
        <MobileLatestBlog blogUrls={blogUrls}></MobileLatestBlog>
      </MediaQuery>
    </React.Fragment>
  );
}
export default Posts;
