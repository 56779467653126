import { API } from "aws-amplify";

export const getThreeDSStatus = async () => {
  try {
    const response = await API.get("v2", `marketplace/threeds-status/get`);
    return response.status;
  } catch (error) {
    // return "on" in case ther is an error when getting 3ds status 
    return "on";
  }
};

export const getMaintenanceStatus = async () => {
  try {
    const response = await API.get("v2", `marketplace/maintenance-status/get`);
    return response.status;
  } catch (error) {
    // return "off" in case ther is an error when getting maintenance status 
    return "off";
  }
};
