export const getZones = rsr => {
  let zones = [];

  var _4 = {
    path:
      'M 777.00,723.00 C 777.00,723.00 777.00,598.00 777.00,598.00 777.00,598.00 777.00,572.00 777.00,572.00 777.00,572.00 778.02,561.60 778.02,561.60 778.02,561.60 786.00,560.00 786.00,560.00 786.00,560.00 899.00,560.00 899.00,560.00 899.00,560.00 899.00,723.00 899.00,723.00 899.00,723.00 777.00,723.00 777.00,723.00 Z',
    attr: {
      id: '_4',
      'data-name': '4',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "4", zone: 'Lower Zone', zoneId: "1" },
  };


  var _5 = {
    path:
      'M 928.00,560.00 C 928.00,560.00 1054.00,560.00 1054.00,560.00 1054.00,560.00 1054.00,723.00 1054.00,723.00 1054.00,723.00 928.00,723.00 928.00,723.00 928.00,723.00 928.00,560.00 928.00,560.00 Z',
    attr: {
      id: '_5',
      'data-name': '5',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "5", zone: 'Lower Zone', zoneId: "1" },
  };

  var _6 = {
    path:
      'M 1083.00,560.00 C 1083.00,560.00 1204.00,560.00 1204.00,560.00 1204.00,560.00 1204.00,723.00 1204.00,723.00 1204.00,723.00 1083.00,723.00 1083.00,723.00 1083.00,723.00 1083.00,560.00 1083.00,560.00 Z',
    attr: {
      id: '_6',
      'data-name': '6',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "6", zone: 'Lower Zone', zoneId: "1" },
  };

  var _7 = {
    path:
      'M 1233.00,560.00 C 1233.00,560.00 1361.00,560.00 1361.00,560.00 1361.00,560.00 1361.00,723.00 1361.00,723.00 1361.00,723.00 1233.00,723.00 1233.00,723.00 1233.00,723.00 1233.00,560.00 1233.00,560.00 Z',
    attr: {
      id: '_7',
      'data-name': '7',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "7", zone: 'Lower Zone', zoneId: "1" },
  };

  var _8 = {
    path:
      'M 1390.00,560.00 C 1390.00,560.00 1511.00,560.00 1511.00,560.00 1511.00,560.00 1511.00,723.00 1511.00,723.00 1511.00,723.00 1390.00,723.00 1390.00,723.00 1390.00,723.00 1390.00,560.00 1390.00,560.00 Z',
    attr: {
      id: '_8',
      'data-name': '8',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "8", zone: 'Lower Zone', zoneId: "1" },
  };

  var _9 = {
    path:
      'M 1540.00,561.00 C 1540.00,561.00 1599.00,561.00 1599.00,561.00 1611.33,561.02 1635.19,569.26 1648.00,573.00 1648.00,573.00 1645.22,583.00 1645.22,583.00 1645.22,583.00 1636.22,613.00 1636.22,613.00 1636.22,613.00 1633.04,622.00 1633.04,622.00 1633.04,622.00 1627.22,643.00 1627.22,643.00 1627.22,643.00 1624.04,652.00 1624.04,652.00 1624.04,652.00 1610.90,696.00 1610.90,696.00 1610.90,696.00 1601.36,727.00 1601.36,727.00 1600.46,729.76 1599.48,736.41 1597.32,737.99 1594.99,739.69 1584.07,734.48 1580.87,733.42 1580.87,733.42 1568.84,729.46 1568.84,729.46 1568.84,729.46 1550.83,725.46 1550.83,725.46 1547.35,724.62 1543.59,725.50 1541.74,724.01 1539.62,722.30 1540.00,717.50 1540.00,715.00 1540.00,715.00 1540.00,561.00 1540.00,561.00 Z',
    attr: {
      id: '_9',
      'data-name': '9',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "9", zone: 'Lower Zone', zoneId: "1" },
  };

  var _10 = {
    path:
      'M 1676.00,581.00 C 1676.00,581.00 1705.00,589.29 1705.00,589.29 1705.00,589.29 1719.00,593.85 1719.00,593.85 1719.00,593.85 1733.00,607.00 1733.00,607.00 1733.00,607.00 1759.00,633.00 1759.00,633.00 1759.00,633.00 1775.00,650.00 1775.00,650.00 1775.00,650.00 1787.00,664.00 1787.00,664.00 1787.00,664.00 1746.00,687.99 1746.00,687.99 1746.00,687.99 1676.00,729.20 1676.00,729.20 1676.00,729.20 1645.00,747.60 1645.00,747.60 1645.00,747.60 1629.04,756.55 1629.04,756.55 1623.27,758.10 1624.90,751.08 1625.75,748.00 1625.75,748.00 1632.96,725.00 1632.96,725.00 1632.96,725.00 1635.36,716.00 1635.36,716.00 1635.36,716.00 1661.77,628.00 1661.77,628.00 1661.77,628.00 1664.96,619.00 1664.96,619.00 1664.96,619.00 1676.00,581.00 1676.00,581.00 Z',
    attr: {
      id: '_10',
      'data-name': '10',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "10", zone: 'Lower Zone', zoneId: "1" },
  };

  var _11 = {
    path:
      'M 1831.00,784.00 C 1831.00,784.00 1692.00,784.00 1692.00,784.00 1692.00,784.00 1657.00,784.00 1657.00,784.00 1654.39,783.96 1647.87,783.83 1649.62,779.34 1650.51,777.07 1657.69,773.39 1660.00,772.00 1660.00,772.00 1676.00,763.05 1676.00,763.05 1676.00,763.05 1688.00,755.40 1688.00,755.40 1688.00,755.40 1698.00,750.05 1698.00,750.05 1698.00,750.05 1711.00,741.81 1711.00,741.81 1711.00,741.81 1721.00,736.56 1721.00,736.56 1721.00,736.56 1734.00,728.23 1734.00,728.23 1734.00,728.23 1761.00,712.99 1761.00,712.99 1761.00,712.99 1802.00,689.00 1802.00,689.00 1802.00,689.00 1815.72,733.00 1815.72,733.00 1815.72,733.00 1825.72,766.00 1825.72,766.00 1825.72,766.00 1831.00,784.00 1831.00,784.00 Z',
    attr: {
      id: '_11',
      'data-name': '11',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "11", zone: 'Lower Zone', zoneId: "1" },
  };

  var _12 = {
    path:
      'M 1665.00,813.00 C 1665.00,813.00 2064.00,813.00 2064.00,813.00 2066.41,813.00 2069.58,812.78 2071.70,814.02 2073.92,815.32 2077.00,819.83 2078.63,822.00 2078.63,822.00 2092.13,840.00 2092.13,840.00 2092.13,840.00 2143.13,908.00 2143.13,908.00 2148.47,915.13 2163.15,932.50 2165.00,940.00 2165.00,940.00 1675.00,940.00 1675.00,940.00 1675.00,940.00 1675.00,863.00 1675.00,863.00 1675.00,863.00 1673.87,842.04 1673.87,842.04 1673.87,842.04 1672.00,830.00 1672.00,830.00 1672.00,830.00 1669.00,830.00 1669.00,830.00 1669.00,830.00 1665.00,813.00 1665.00,813.00 Z',
    attr: {
      id: '_12',
      'data-name': '12',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "12", zone: 'Lower Zone', zoneId: "1" },
  };

  var _13 = {
    path:
      'M 1675.00,969.00 C 1675.00,969.00 2165.00,969.00 2165.00,969.00 2165.00,969.00 2165.00,1101.00 2165.00,1101.00 2165.00,1101.00 1669.00,1101.00 1669.00,1101.00 1669.46,1094.70 1670.64,1096.39 1671.70,1091.96 1673.84,1083.06 1674.99,1073.18 1675.00,1064.00 1675.00,1064.00 1675.00,969.00 1675.00,969.00 Z',
    attr: {
      id: '_13',
      'data-name': '13',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "13", zone: 'Lower Zone', zoneId: "1" },
  };

  var _14 = {
    path:
      'M 1653.00,1139.00 C 1653.00,1139.00 1656.85,1131.28 1656.85,1131.28 1656.85,1131.28 1665.00,1130.00 1665.00,1130.00 1665.00,1130.00 1683.00,1130.00 1683.00,1130.00 1683.00,1130.00 1774.00,1130.00 1774.00,1130.00 1774.00,1130.00 2164.00,1130.00 2164.00,1130.00 2164.00,1130.00 2147.63,1195.00 2147.63,1195.00 2147.63,1195.00 2116.87,1318.00 2116.87,1318.00 2116.87,1318.00 2097.00,1395.00 2097.00,1395.00 2097.00,1395.00 2080.00,1385.99 2080.00,1385.99 2080.00,1385.99 2055.00,1371.23 2055.00,1371.23 2055.00,1371.23 2034.00,1359.58 2034.00,1359.58 2034.00,1359.58 1988.00,1332.44 1988.00,1332.44 1988.00,1332.44 1856.00,1256.01 1856.00,1256.01 1856.00,1256.01 1784.00,1214.80 1784.00,1214.80 1784.00,1214.80 1774.00,1208.44 1774.00,1208.44 1774.00,1208.44 1756.00,1198.58 1756.00,1198.58 1756.00,1198.58 1720.00,1177.80 1720.00,1177.80 1720.00,1177.80 1711.00,1172.02 1711.00,1172.02 1711.00,1172.02 1687.00,1158.58 1687.00,1158.58 1687.00,1158.58 1653.00,1139.00 1653.00,1139.00 Z',
    attr: {
      id: '_14',
      'data-name': '14',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "14", zone: 'Lower Zone', zoneId: "1" },
  };

  var _15 = {
    path:
      'M 1640.00,1165.00 C 1640.00,1165.00 1706.00,1202.99 1706.00,1202.99 1706.00,1202.99 1731.00,1217.20 1731.00,1217.20 1731.00,1217.20 1740.00,1222.98 1740.00,1222.98 1740.00,1222.98 1759.00,1233.42 1759.00,1233.42 1759.00,1233.42 1795.00,1254.20 1795.00,1254.20 1795.00,1254.20 1805.00,1260.56 1805.00,1260.56 1805.00,1260.56 1859.00,1291.20 1859.00,1291.20 1859.00,1291.20 1868.00,1296.98 1868.00,1296.98 1868.00,1296.98 1878.00,1302.23 1878.00,1302.23 1878.00,1302.23 1933.00,1334.56 1933.00,1334.56 1933.00,1334.56 1942.00,1339.23 1942.00,1339.23 1942.00,1339.23 2001.00,1373.99 2001.00,1373.99 2001.00,1373.99 2011.00,1379.23 2011.00,1379.23 2011.00,1379.23 2020.00,1385.05 2020.00,1385.05 2020.00,1385.05 2082.00,1421.00 2082.00,1421.00 2082.00,1421.00 2056.00,1448.00 2056.00,1448.00 2056.00,1448.00 2021.00,1482.96 2021.00,1482.96 2021.00,1482.96 2000.00,1503.00 2000.00,1503.00 2000.00,1503.00 1940.00,1562.99 1940.00,1562.99 1940.00,1562.99 1929.00,1573.01 1929.00,1573.01 1929.00,1573.01 1894.00,1607.00 1894.00,1607.00 1894.00,1607.00 1868.42,1563.00 1868.42,1563.00 1868.42,1563.00 1857.99,1544.00 1857.99,1544.00 1857.99,1544.00 1826.81,1491.00 1826.81,1491.00 1826.81,1491.00 1821.56,1481.00 1821.56,1481.00 1821.56,1481.00 1799.81,1444.00 1799.81,1444.00 1799.81,1444.00 1795.05,1435.00 1795.05,1435.00 1795.05,1435.00 1789.23,1426.00 1789.23,1426.00 1789.23,1426.00 1783.99,1416.00 1783.99,1416.00 1783.99,1416.00 1762.23,1379.00 1762.23,1379.00 1762.23,1379.00 1758.05,1371.00 1758.05,1371.00 1758.05,1371.00 1752.23,1362.00 1752.23,1362.00 1752.23,1362.00 1747.56,1353.00 1747.56,1353.00 1747.56,1353.00 1725.23,1315.00 1725.23,1315.00 1725.23,1315.00 1712.99,1293.00 1712.99,1293.00 1712.99,1293.00 1665.40,1211.00 1665.40,1211.00 1665.40,1211.00 1649.56,1183.00 1649.56,1183.00 1649.56,1183.00 1640.00,1165.00 1640.00,1165.00 Z',
    attr: {
      id: '_15',
      'data-name': '15',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "15", zone: 'Lower Zone', zoneId: "1" },
  };

  var _16 = {
    path:
      'M 1611.00,1176.00 C 1611.00,1176.00 1622.20,1194.00 1622.20,1194.00 1622.20,1194.00 1644.44,1233.00 1644.44,1233.00 1644.44,1233.00 1661.58,1262.00 1661.58,1262.00 1661.58,1262.00 1671.44,1280.00 1671.44,1280.00 1671.44,1280.00 1677.80,1290.00 1677.80,1290.00 1677.80,1290.00 1708.44,1344.00 1708.44,1344.00 1708.44,1344.00 1714.80,1354.00 1714.80,1354.00 1714.80,1354.00 1740.77,1399.00 1740.77,1399.00 1740.77,1399.00 1746.02,1409.00 1746.02,1409.00 1746.02,1409.00 1751.80,1418.00 1751.80,1418.00 1751.80,1418.00 1782.44,1472.00 1782.44,1472.00 1782.44,1472.00 1811.77,1522.00 1811.77,1522.00 1811.77,1522.00 1817.01,1532.00 1817.01,1532.00 1817.01,1532.00 1853.44,1595.00 1853.44,1595.00 1853.44,1595.00 1868.00,1620.00 1868.00,1620.00 1868.00,1620.00 1808.00,1636.12 1808.00,1636.12 1808.00,1636.12 1685.00,1669.42 1685.00,1669.42 1685.00,1669.42 1638.00,1682.15 1638.00,1682.15 1638.00,1682.15 1610.00,1689.00 1610.00,1689.00 1610.00,1689.00 1610.00,1339.00 1610.00,1339.00 1610.00,1339.00 1610.00,1230.00 1610.00,1230.00 1610.00,1230.00 1610.00,1197.00 1610.00,1197.00 1610.00,1197.00 1611.00,1176.00 1611.00,1176.00 Z',
    attr: {
      id: '_16',
      'data-name': '16',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "16", zone: 'Lower Zone', zoneId: "1" },
  };

  var _17 = {
    path:
      'M 1579.00,1190.00 C 1579.00,1190.00 1581.00,1190.00 1581.00,1190.00 1581.00,1190.00 1581.00,1691.00 1581.00,1691.00 1581.00,1691.00 1461.00,1691.00 1461.00,1691.00 1461.00,1691.00 1461.00,1200.00 1461.00,1200.00 1461.00,1200.00 1523.00,1200.00 1523.00,1200.00 1533.65,1199.98 1530.33,1198.03 1541.00,1198.00 1551.46,1197.98 1546.88,1197.34 1553.17,1195.99 1553.17,1195.99 1559.91,1195.99 1559.91,1195.99 1559.91,1195.99 1565.17,1194.24 1565.17,1194.24 1570.37,1193.28 1574.41,1193.49 1579.00,1190.00 Z',
    attr: {
      id: '_17',
      'data-name': '17',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "17", zone: 'Lower Zone', zoneId: "1" },
  };

  var _18 = {
    path:
      'M 1313.00,1200.00 C 1313.00,1200.00 1432.00,1200.00 1432.00,1200.00 1432.00,1200.00 1432.00,1691.00 1432.00,1691.00 1432.00,1691.00 1358.00,1691.00 1358.00,1691.00 1358.00,1691.00 1313.00,1690.00 1313.00,1690.00 1313.00,1690.00 1313.00,1200.00 1313.00,1200.00 Z',
    attr: {
      id: '_18',
      'data-name': '18',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "18", zone: 'Lower Zone', zoneId: "1" },
  };

  var _19 = {
    path:
      'M 1161.00,1200.00 C 1161.00,1200.00 1284.00,1200.00 1284.00,1200.00 1284.00,1200.00 1284.00,1690.00 1284.00,1690.00 1284.00,1690.00 1161.00,1690.00 1161.00,1690.00 1161.00,1690.00 1161.00,1200.00 1161.00,1200.00 Z',
    attr: {
      id: '_19',
      'data-name': '19',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "19", zone: 'Lower Zone', zoneId: "1" },
  };

  var _20 = {
    path:
      'M 1012.00,1200.00 C 1012.00,1200.00 1132.00,1200.00 1132.00,1200.00 1132.00,1200.00 1132.00,1690.00 1132.00,1690.00 1132.00,1690.00 1012.00,1690.00 1012.00,1690.00 1012.00,1690.00 1012.00,1200.00 1012.00,1200.00 Z',
    attr: {
      id: '_20',
      'data-name': '20',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "20", zone: 'Lower Zone', zoneId: "1" },
  };

  var _21 = {
    path:
      'M 861.00,1200.00 C 861.00,1200.00 983.00,1200.00 983.00,1200.00 983.00,1200.00 983.00,1690.00 983.00,1690.00 983.00,1690.00 861.00,1690.00 861.00,1690.00 861.00,1690.00 861.00,1200.00 861.00,1200.00 Z',
    attr: {
      id: '_21',
      'data-name': '21',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "21", zone: 'Lower Zone', zoneId: "1" },
  };

  var _22 = {
    path:
      'M 710.00,1192.00 C 710.00,1192.00 728.09,1195.99 728.09,1195.99 728.09,1195.99 734.83,1195.99 734.83,1195.99 734.83,1195.99 739.17,1197.77 739.17,1197.77 739.17,1197.77 749.00,1197.77 749.00,1197.77 749.00,1197.77 764.00,1200.00 764.00,1200.00 764.00,1200.00 832.00,1200.00 832.00,1200.00 832.00,1200.00 832.00,1690.00 832.00,1690.00 832.00,1690.00 710.00,1690.00 710.00,1690.00 710.00,1690.00 710.00,1192.00 710.00,1192.00 Z',
    attr: {
      id: '_22',
      'data-name': '22',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "22", zone: 'Lower Zone', zoneId: "1" },
  };

  var _23 = {
    path:
      'M 419.00,1620.00 C 419.00,1620.00 445.98,1574.00 445.98,1574.00 445.98,1574.00 451.81,1563.00 451.81,1563.00 451.81,1563.00 498.99,1483.00 498.99,1483.00 498.99,1483.00 504.23,1473.00 504.23,1473.00 504.23,1473.00 510.05,1464.00 510.05,1464.00 510.05,1464.00 514.23,1456.00 514.23,1456.00 514.23,1456.00 548.99,1397.00 548.99,1397.00 548.99,1397.00 554.23,1387.00 554.23,1387.00 554.23,1387.00 560.05,1378.00 560.05,1378.00 560.05,1378.00 564.23,1370.00 564.23,1370.00 564.23,1370.00 601.99,1306.00 601.99,1306.00 601.99,1306.00 606.20,1298.00 606.20,1298.00 606.20,1298.00 611.98,1289.00 611.98,1289.00 611.98,1289.00 617.23,1279.00 617.23,1279.00 617.23,1279.00 641.99,1237.00 641.99,1237.00 641.99,1237.00 659.49,1207.00 659.49,1207.00 659.49,1207.00 671.40,1186.00 671.40,1186.00 672.60,1183.97 674.78,1179.42 677.10,1178.73 681.58,1177.40 681.00,1185.62 681.00,1188.00 681.00,1188.00 681.00,1689.00 681.00,1689.00 681.00,1689.00 665.00,1684.88 665.00,1684.88 665.00,1684.88 627.00,1674.88 627.00,1674.88 627.00,1674.88 528.00,1648.88 528.00,1648.88 528.00,1648.88 453.00,1629.15 453.00,1629.15 453.00,1629.15 419.00,1620.00 419.00,1620.00 Z',
    attr: {
      id: '_23',
      'data-name': '23',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "23", zone: 'Lower Zone', zoneId: "1" },
  };

  var _24 = {
    path:
      'M 210.00,1420.00 C 210.00,1420.00 278.00,1380.58 278.00,1380.58 278.00,1380.58 389.00,1316.56 389.00,1316.56 389.00,1316.56 435.00,1289.42 435.00,1289.42 435.00,1289.42 454.00,1278.99 454.00,1278.99 454.00,1278.99 517.00,1242.56 517.00,1242.56 517.00,1242.56 563.00,1215.42 563.00,1215.42 563.00,1215.42 600.00,1194.40 600.00,1194.40 600.00,1194.40 627.00,1179.05 627.00,1179.05 627.00,1179.05 649.00,1167.00 649.00,1167.00 649.00,1167.00 620.42,1216.00 620.42,1216.00 620.42,1216.00 571.01,1301.00 571.01,1301.00 571.01,1301.00 555.77,1328.00 555.77,1328.00 555.77,1328.00 508.01,1409.00 508.01,1409.00 508.01,1409.00 502.77,1419.00 502.77,1419.00 502.77,1419.00 496.95,1428.00 496.95,1428.00 496.95,1428.00 492.77,1436.00 492.77,1436.00 492.77,1436.00 458.01,1495.00 458.01,1495.00 458.01,1495.00 452.77,1505.00 452.77,1505.00 452.77,1505.00 446.95,1514.00 446.95,1514.00 446.95,1514.00 442.77,1522.00 442.77,1522.00 442.77,1522.00 405.01,1586.00 405.01,1586.00 405.01,1586.00 400.80,1594.00 400.80,1594.00 400.80,1594.00 394.00,1605.00 394.00,1605.00 387.28,1601.82 375.80,1588.80 370.00,1583.00 370.00,1583.00 324.83,1537.00 324.83,1537.00 324.83,1537.00 295.00,1507.00 295.00,1507.00 295.00,1507.00 249.04,1461.00 249.04,1461.00 249.04,1461.00 240.96,1452.00 240.96,1452.00 240.96,1452.00 210.00,1420.00 210.00,1420.00 Z',
    attr: {
      id: '_24',
      'data-name': '24',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "24", zone: 'Lower Zone', zoneId: "1" },
  };

  var _25 = {
    path:
      'M 126.00,1136.00 C 126.00,1136.00 640.00,1136.00 640.00,1136.00 637.56,1139.88 632.90,1141.91 629.00,1144.15 629.00,1144.15 608.00,1156.42 608.00,1156.42 608.00,1156.42 594.00,1164.01 594.00,1164.01 594.00,1164.01 540.00,1195.77 540.00,1195.77 540.00,1195.77 529.00,1201.60 529.00,1201.60 529.00,1201.60 349.00,1306.19 349.00,1306.19 349.00,1306.19 339.00,1311.44 339.00,1311.44 339.00,1311.44 305.00,1331.40 305.00,1331.40 305.00,1331.40 274.00,1349.01 274.00,1349.01 274.00,1349.01 230.00,1374.85 230.00,1374.85 230.00,1374.85 210.00,1386.01 210.00,1386.01 204.72,1389.06 200.05,1392.77 194.00,1394.00 194.00,1394.00 186.88,1366.00 186.88,1366.00 186.88,1366.00 174.42,1319.00 174.42,1319.00 174.42,1319.00 143.12,1201.00 143.12,1201.00 143.12,1201.00 131.12,1156.00 131.12,1156.00 131.12,1156.00 126.00,1136.00 126.00,1136.00 Z',
    attr: {
      id: '_25',
      'data-name': '25',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "25", zone: 'Lower Zone', zoneId: "1" },
  };

  var _26 = {
    path:
      'M 126.00,967.00 C 126.00,967.00 482.00,967.00 482.00,967.00 482.00,967.00 580.00,967.00 580.00,967.00 580.00,967.00 606.00,967.00 606.00,967.00 612.05,967.00 616.52,966.12 622.00,969.00 622.00,969.00 622.00,971.00 622.00,971.00 619.56,974.31 620.01,976.03 620.00,980.00 620.00,980.00 620.00,1007.00 620.00,1007.00 620.00,1007.00 620.00,1058.00 620.00,1058.00 620.02,1068.94 621.95,1064.31 622.00,1074.00 622.06,1085.87 625.34,1095.69 628.00,1107.00 628.00,1107.00 126.00,1107.00 126.00,1107.00 126.00,1107.00 126.00,967.00 126.00,967.00 Z',
    attr: {
      id: '_26',
      'data-name': '26',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "26", zone: 'Lower Zone', zoneId: "1" },
  };

  var _27 = {
    path:
      'M 126.00,938.00 C 126.00,938.00 150.12,904.00 150.12,904.00 150.12,904.00 193.15,845.00 193.15,845.00 193.15,845.00 207.12,826.00 207.12,826.00 207.12,826.00 214.09,818.02 214.09,818.02 214.09,818.02 222.00,817.00 222.00,817.00 222.00,817.00 508.00,817.00 508.00,817.00 508.00,817.00 591.00,817.00 591.00,817.00 591.00,817.00 615.00,817.00 615.00,817.00 615.00,817.00 628.00,818.00 628.00,818.00 623.68,823.68 624.79,827.99 623.76,833.83 623.06,837.82 622.11,836.48 622.00,843.00 622.00,843.00 622.00,938.00 622.00,938.00 622.00,938.00 126.00,938.00 126.00,938.00 Z',
    attr: {
      id: '_27',
      'data-name': '27',
      opacity: 0,
      fill: '#2196f3',
      stroke: '#d7d7d7',
      'stroke-miterlimit': '10',
      'stroke-width': '0',
      'stroke-opacity': '1',
    },
    transform: "",
    data: { zoneNo: "27", zone: 'Lower Zone', zoneId: "1" },
  };

  zones.push(
    _4,
    _5,
    _6,
    _7,
    _8,
    _9,
    _10,
    _11,
    _12,
    _13,
    _14,
    _15,
    _16,
    _17,
    _18,
    _19,
    _20,
    _21,
    _22,
    _23,
    _24,
    _25,
    _26,
    _27
  );
  return zones;
};
