import React from "react";
import { Typography, withStyles } from "@material-ui/core";

import BuyerTrustSeal from "../../images/buyertrust-seal.svg";

const styles = (theme) => ({
  teamSelectorContainer: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    borderBottom: "1px solid #D5DEE9",
  },
  teamButton: {
    flex: 1,
    borderRadius: 0,
    borderBottom: "3px solid rgba(255,255,255,0)",
    fontWeight: 400,
    textTransform: "none",
  },  
  finePrint: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D5DEE9",
  },
  [theme.breakpoints.up("md")]: {
    teamSelectorContainer: {
      borderTop: "1px solid #D5DEE9",
    },
    teamButton: {
      minWidth: theme.spacing.unit * 20,
      fontSize: "1.5rem",
      padding: "1rem",
    },
    finePrint: {
      padding: "1.5rem",
    },
  },
  [theme.breakpoints.down("md")]: {
    teamSelectorContainer: {
      boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.05)",
    },
    teamButton: {
      minWidth: theme.spacing.unit * 8,
      padding: ".75rem",
    },
    finePrint: {
      padding: ".5rem",
    },
  },
});

const TeamSelector = ({
  classes
}) => {

  return (
    <React.Fragment>
      <div className={classes.finePrint}>
        <img src={BuyerTrustSeal} style={{ marginRight: ".5rem" }} />
        <Typography variant="caption" align="center" color="textSecondary">
          <strong>100% Guaranteed</strong>. All Prices CAD.
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(TeamSelector);
